import React from "react"
import "./../../css/page/videos.css"
import videosData from "./../../../data/videos.yaml"

class Videos extends React.Component {

  setSingleTag

  constructor(props) {
    super(props)
    this.state = { tagsOn: props.tagsOn }
  }

  getVideos = () => {
    // videosData.forEach((video) => videosOrdered.push(video))
    let videosOrdered = videosData.filter((video) => {
      let show = false
      if (this.state.tagsOn.length === 0) {
        show = true
      } else {
        this.state.tagsOn.forEach((tagOn) => {
          video.tags.forEach((tag) => {
            if (tagOn === tag.item) show = true
          })
        })
      }
      return show
    })
    videosOrdered.sort((a, b) => a.idx > b.idx ? -1 : 1)
    return videosOrdered
  }

  insertVideo = (video, key) => (
    <article key={key}>
      <div className="thumbnail"
        role="button"
        onClick={() => { window.location.href = `/${video.link}` }}
        onKeyDown={() => { window.location.href = `/${video.link}` }}
        tabIndex={0}
      >
        <div className="desc">
          <div className="title">
            {video.title}
          </div>
          {video.desc}
        </div>
        <div className="image" style={{
          backgroundImage: `url('/images/videos/thumbnails/${video.idx}.png')`
        }}></div>
      </div>
      <div className="tags">
        {video.tags.map((tag, tagKey) => (
          <div 
            key={tagKey}
            role="button"
            onClick={() => { window.location.href = `/?tag=${tag.item}` }}
            onKeyDown={() => { window.location.href = `/?tag=${tag.item}` }}
            tabIndex={0}
          >
            <span>#</span> {tag.item}
          </div>
        ))}
      </div>
    </article>
  )

  render () {
    return (
      <section id="video-thumbs" className="centered">
        {this.getVideos().map((video, key) => this.insertVideo(video, key))}
      </section>
    )
  }

}

export default Videos