import React from "react"
import Layout from "../components/js/layout"
import SEO from "../components/js/seo"
import Videos from "../components/js/page/videos"
import videosData from "./../data/videos.yaml"
import { from } from "rxjs"
import { map, distinct, toArray } from "rxjs/operators"
import queryString from "query-string"

import "../components/css/page/videos.css"

class YalcodicPage extends React.Component {

  state = { 
    didMount: false,
    tagsOn: [],
    tagsOpen: false 
  }

  getDistinctTags () {
    let tags = []
    let distinctTags
    videosData.forEach(video => {
      tags = tags.concat(video.tags)
    })
    from(tags).pipe(
      map(tag => tag.item),
      distinct(),
      toArray(),
      map(array => {
        let sorted = [].concat(array)
        sorted.sort((a, b) => a > b ? 1 : -1)
        return sorted
      })
    ).subscribe(result => {distinctTags = result})
    return distinctTags
  }

  toggleTag (tag) {
    let tagsOn = this.state.tagsOn;
    if (tagsOn.includes(tag)) {
      tagsOn.splice(tagsOn.indexOf(tag), 1)
    } else {
      tagsOn.push(tag)
    }
    this.setState(() => {return { tagsOn: tagsOn }})
  }

  setSingleTag (tag) {
    let tagsOn = this.state.tagsOn;
    tagsOn.splice(0, tagsOn.length)
    tagsOn.push(tag)
    this.setState(() => { return { tagsOn: tagsOn } })
  }

  emptyTags () {
    let tagsOn = this.state.tagsOn;
    tagsOn.splice(0, tagsOn.length)
    this.setState(() => { return { tagsOn: tagsOn } })
  }

  toggleTagsOpen () {
    let tagsOpen = this.state.tagsOpen;
    this.setState(() => { return { tagsOpen: !tagsOpen }})
  }

  render () {
    const tags = this.getDistinctTags()
    return !this.state.didMount ? null :  (
      <Layout page="videos">
        <SEO title="얄팍한 코딩사전" />
        <div id="tags-space" className={this.state.tagsOpen ? 'open' : ''}>
          <div className="tags">
            <div 
              role="button"
              className={this.state.tagsOn.length === 0 ? 'on' : ''}
              onClick={() => this.emptyTags()}
              onKeyDown={() => this.emptyTags()}
              tabIndex={0}
            >
              <span>--</span> 전체 보기 <span>--</span>
            </div>
            {tags.map((tag, key) => (
              <div 
                role="button"
                key={key}
                className={this.state.tagsOn.includes(tag) ? 'on' : ''}
                onClick={() => this.toggleTag(tag)}
                onKeyDown={() => this.toggleTag(tag)}
                tabIndex={0}
              >
                <span>#</span> {tag}
              </div>
            ))}
          </div>
        </div>
        <div id="tags-space-toggle" 
          role="button"
          onClick={ () => this.toggleTagsOpen() }
          onKeyDown={ () => this.toggleTagsOpen() }
          tabIndex="0"
        >
          { this.state.tagsOpen ? '⬆️' : '⬇️' } 태그들 {this.state.tagsOpen ? '접기' : '펼치기'}
        </div>
        <Videos 
          tagsOn={this.state.tagsOn}
        ></Videos>
      </Layout>
    )
  }

  componentDidMount () {
    const queries = queryString.parse(window.location.search)
    if (queries.tag) {
      this.state.tagsOn.push(queries.tag)
    }
    this.setState(() => {
      return { didMount: true }
    })
  }
}


// const YalcodicPage = () => {
// }

export default YalcodicPage
